@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.project {

    &__section {
        padding: 3rem 2rem;

        & ul {
            font-size: 1.2rem;
        }
    }

    &__subsection {
        margin-top: 5rem;
    }

    &__main {
        box-shadow: 12px 0 15px -20px rgba(0,0,0,0.75) inset;
        background-color: white;
    }

    &__container {
        display: flex;
        justify-content: center;
        padding: 1rem 2rem 0 0;
        gap: 1rem;
    }

    &__overview {
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 300;
        width: 45%;
        margin-top: 2rem;
    }

    &__img-full {
        width: 82%;
        margin: 0 9%;
    }

    &__img-half {
        width: 55%;
    }

    &__img-third {
        width: 33%;
    }
}