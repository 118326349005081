@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/keyframes' as *;

.about {
    margin-top: 2rem;
    
    &__avatar {
        width: 8rem;
        height: 8rem;
    }

    &__divider {
        width: 25rem;
        border-bottom: 1px solid white;
        margin: 2rem 0;
    }

    &__container {
        display: flex;
    }
    
    &__bubble {
        transform: translatey(0px);
        animation: float 5s ease-in-out infinite;
        mix-blend-mode: multiply;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 3px;
        font-size: 1rem;
        color: black;
        background-color: $color-beige;
        padding: 1rem;
        border-radius: 11px;
        position: relative;
        box-shadow: 15px 15px $color-blue;
        height: 1.5rem;

        &::after {
            transform: translatey(0px);
            animation: float2 5s ease-in-out infinite;
            content: ".";
            font-weight: bold;
            text-shadow: 22px 22px $color-blue;
            text-align: left;
            font-size: 55px;
            width: 55px;
            height: 11px;
            line-height: 30px;
            border-radius: 11px;
            background-color: $color-beige;
            position: absolute;
            display: block;
            bottom: -30px;
            left: 0;
            box-shadow: 22px 22px $color-blue;
            z-index: -2;
        }
    }
}