@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.flippers {
    display: flex;
    gap: 6rem;
    justify-content: center;
    padding: 10rem 0 6rem 0;
    
    &__flipper {
        width: 25%;
        transition: all 0.1s;
    }

    &__activeLeft {
        transform: rotate(-18deg) translateY(-36.66%) translateX(3%);
        transition: all 0.1s;
    }

    &__activeRight {
        transform: rotate(18deg) translateY(-36.66%) translateX(-3%);
        transition: all 0.1s;
    }
}