@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

$slot-diameter: $slot-width;
$border-thickness: 2px;

.item {
    width: $slot-diameter;
    height: $slot-diameter;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    border: $border-thickness solid $primary-background;
    box-sizing: border-box;
    box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.85);

    &:hover{
        box-shadow: none;
        border: 2px solid white;
        // transform: translate(5px, 5px);

        & .item__information {
            transition: all 0.15s;
            height: 100%;
        }
    
        & .item__overlay {
            transition: all 0.1s;
            opacity: 90%;
        }

        & .item__title {
            margin-top: 25%;
            font-size: 2rem;
            font-weight: 800;
        }

        & .item__description {
            font-size: 1.1rem;
            font-weight: 600;
        }

        & .item__description--extended {
            display: block;
            width: calc($slot-diameter*3/4);
            margin-top: 1rem;
        }
    }

    &__list {
        display: flex;
        width: 100%;
        height: fit-content;
        overflow: hidden;
        position: relative;
        transition: all 0.1s;

        & .item__information {
            position: relative;
            margin-left: 1rem;
            box-shadow: none;
        }
    
        & .item__overlay {
            opacity: 0;
        }

        & .item__title {
            text-align: left;
        }

        & .item__description {
            text-align: left;
            margin: 0;
        }

        & .item__image {
            box-shadow: none;
        }
    }

    &__image {
        border-radius: 50%;
        width: $slot-diameter;
        height: $slot-diameter;
        background-position: center;
        background-size: cover;
        position: absolute;
        box-shadow: 0 0 28px 0 rgba(0,0,0,0.5) inset;
        // border: 3px solid #e9e9e9;
        // border: 6px solid white;

        &--list {
            position: static;
            width: 8rem;
            height: 8rem;
            transition: all 0.1s;
        }
    }

    &__information {
        width: calc($slot-diameter + $border-thickness *2);
        height: 28%;
        bottom: 0;
        position: absolute;
        overflow: hidden;
        box-shadow: 0 0 28px 0px rgba(0,0,0,0.5);

        &:hover{
            background: none;
        }
    }

    &__overlay {
        // border-radius: calc($slot-diameter/2 + $border-thickness) calc($slot-diameter/2 + $border-thickness) calc($slot-diameter/2 + $border-thickness) calc($slot-diameter/2 + $border-thickness);
        width: calc($slot-diameter + $border-thickness *2);
        height: calc($slot-diameter + $border-thickness *2);
        bottom: 0;
        right: 0;
        position: absolute;
        background-color: $primary-background;
    }

    &__title {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0.5rem 0 0.3rem 0;
        pointer-events: none;
        position: relative;
        color: rgb(56, 56, 56);
    }

    &__description {
        text-align: center;
        margin: auto;
        pointer-events: none;
        position: relative;
        width: calc($slot-diameter/2);

        &--extended {
            display: none;
        }
    }

    &__hit {
        background-color: rgb(136, 202, 136);
        position: relative;
        padding: 0.6rem;
        width: fit-content;
        bottom: calc($slot-diameter * 9/10);
        right: calc($slot-diameter * -9/10);
        border-radius: 0.5rem;
        font-weight: 800;
        color: green;
        margin-top: -3rem;

        &:hover {
            border: none;
        }
    }
}