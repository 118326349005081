@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.not-found {
    border: 2px solid black;
    font-size: 1.5rem;
    width: 90%;
    margin: auto;
    padding: 3rem;
    box-sizing: border-box;
    margin-top: 20vh;
    @include tablet {
        width: 50%;
    }

    &__text {
        color: black;
    }
}