@use "mixins";
@use "colors";

@font-face {
    font-family: 'Source';
    src: 
        url('../../assets/fonts/SourceSansPro-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Source';
    src: 
        url('../../assets/fonts/SourceSansPro-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source';
    src: 
        url('../../assets/fonts/SourceSansPro-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Source';
    src: 
        url('../../assets/fonts/SourceSansPro-SemiBold.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Source';
    src: 
        url('../../assets/fonts/SourceSansPro-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source';
    src: 
        url('../../assets/fonts/SourceSansPro-Black.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Segment';
    src: 
        url('../../assets/fonts/SevenSegment.ttf');
}

$font-stack: "Source", sans-serif;
$root-font-size: 14px;