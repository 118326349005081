@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

$section-width: calc(100vw - $menu-width - 6rem - 14px);

.hero {
    position: relative;
    background-attachment: fixed;
    background-repeat: no-repeat;

    width: $section-width;
    height: calc(100vh * 1.5);

    &__fit-width {
        background-size: $section-width;
        background-position: right bottom;
    }

    &__fit-height {
        background-size: auto 100vh;
        background-position: center bottom;
    }

    &__image {
        width: $section-width;
        height: 100%;
        object-fit: contain;
        position: fixed;
    }

    &__curtain-box{
        position: fixed;
        overflow: hidden;
        width: $section-width;
        height: 100vh;
        top: 0;
        pointer-events: none;
    }

    &__curtain {
        position: absolute;
        width: $menu-width;
        height: $menu-width;
        border-radius: 50%;
        pointer-events: none;
        box-shadow: 0 0 0 9999px $primary-background, 0 0 10px 0px rgba(0,0,0,0.5) inset;
    }

    &__info {
        z-index: 10;
        position: relative;
        border-left: 3px solid black;
        padding-left: 1rem;

        & p {
            margin: 0.3rem 0;
            font-size: 0.8rem;
            @include desktop {
                font-size: 1rem;
                margin: 0.6rem 0;
            }
        }
    }

    &__details {
        max-width: 22rem;
    }

    &__description {
        padding-bottom: 1rem;
    }

    &__link {
        text-decoration: underline;
    }
}