$primary-black: #13182C;
$primary-white: #FFFFFF;
// $primary-background: #d4d4d4;
// $primary-background: #e8e8e8;
$primary-background: #e9eef1;

$color-blue: #b6c5ce;
$color-blue-dark: #a6b8c3;
$color-beige: #e9eef1;

$secondary-slate: #5C667E;
$secondary-cloud: #BDC5D5;

$background-graphite: #232940;
$background-light-grey: #F7F8F9;

$supporting-green: #158463;
$supporting-red: #C94515;

$primary-editable: #1045c0;
$secondary-editable: #5C667E;