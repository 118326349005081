@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/typography' as *;

.header {
    display: flex;
    justify-content: space-around;
    margin-bottom: -1rem;
    min-width: calc(100vw - 14px);

    &__section {
        width: $slot-width;
    }

    &__score {
        // border: 1px solid black;
        // border-top: none;
        width: 100%;
        // padding: 0.5rem;
        box-sizing: border-box;
        position: relative;
        text-align: right;

        &:hover {
            & .header__instructions-expanded {
                opacity: 0;
            }
            & .header__instructions-collapsed {
                display: block;
            }
        }
    }

    &__instructions-collapsed {
        display: none;
        position: absolute;
        // width: $slot-width;
        width: 50%;
        left: 50%;
        box-sizing: border-box;
        margin: 0.5rem 0 0 -0.5rem;
        z-index: 10;
        padding: 0.8rem;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        text-align: left;
        margin-top: -2rem;

        & p {
            margin: 1rem 0;
        }
    }

    &__instructions-expanded {
        font-weight: 500;
    }

    &__digits {
        font-family: 'Segment';
        font-size: 3.5rem;
        letter-spacing: 0.5rem;
        margin: 0 0.5rem;
    }

    &__title {
        width: 35%;
        position: absolute;
        left: 250px;
        top: 50px;

        // width: 100%;

        & img {
            width: 100%;
        }

        &:hover .header__underline{
            display: block;
            width: 40%;
            border-top: 2px dashed black;
            margin-top: 4px;
            z-index: 10;
            padding: 0.8rem;
            // background-color: rgba(0, 0, 0, 0.7);
            box-shadow: inset 300px 0 0 0 rgba(0, 0, 0, 0.7);
            color: white;

        }
    }

    &__underline {
        // background-color: transparent;
        inset: 0 0 0 0 #54b3d6;
        color: transparent;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    }

    &__pipe {
        position: absolute;
        top: 0;
        left: 100px;
    }

    &__titles {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        
        & h1 {
            font-size: 10rem;
        }

        &:hover .header__underlines{
            display: block;
            border-top: 2px dashed black;
            margin-top: 4px;
            z-index: 10;
            box-shadow: inset 300px 0 0 0 rgba(0, 0, 0, 0.7);
            color: white;
        }
    }

    &__subtitle {
        margin: auto;
        text-align: center;
        font-size: 3rem;
    }

    &__underlines {
        position: absolute;
        top: 12rem;
        width: 40%;
        height: 2rem;
        inset: 0 0 0 0 #54b3d6;
        color: transparent;
        padding: 0.3rem;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    }

    &__container {
        display: flex;
        justify-content: center;
    }
}