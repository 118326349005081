@use '../../styles/partials/globals' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.menu {
    margin: 1rem 3rem;

    &__container {
        display: flex;
        justify-content: space-around;
        gap: 3rem;
        flex-wrap: wrap;
        transition: all 2s;

        &--list {
            flex-direction: column;
            width: $menu-width;
            gap: 1.5rem;
            transition: all 2s;
        }
    }

    &__back {
        display: block;
        left: 3rem;

        &--list {
            display: none;
        }

        &:hover {
            left: 0;
            transform: translateX(-1rem);
            transition: all 0.1s;
        }
    }

    &__filters {
        margin: 3rem 15% 5rem 15%;
        display: flex;
        gap: 1rem;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__filter {
        padding: 0.4rem 0.8rem;
        border: 1.2px solid black;
        border-radius: 1rem;
        cursor: pointer;

        &--active {
            background-color: #b6c5ce;
            border: 2px solid black;
            padding: calc(0.4rem - 2px) calc(0.8rem - 2px);
        }

        &--inactive {
            border: 1px solid darkgray;
            color: darkgray;
        }

        &:hover {
            border: 3px solid black;
            padding: calc(0.4rem - 3px) calc(0.8rem - 3px);
            font-weight: 500;
        }
    }
}